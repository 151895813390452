import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import {
  rawRubricQuery,
  rawTopicsQuery,
} from 'core/queries/rubric';

import { isGameCompare } from 'core/components/GameCompare/utils';

import Rubric from 'core/components/Rubric';

import RubricHeader from 'site/components/RubricHeader';
import BasePage from 'site/components/BasePage';

import Topics from './Topics';


const LIMIT = 20;

export default function RubricPage(props) {
  const { location, match, history } = props;

  const [
    { data: rawRubric, isLoading: rubricIsLoading },
    { data: rawTopics, isLoading: topicsAreLoading },
  ] = useRequest({ queries: [
    rawRubricQuery({ match, history }),
    rawTopicsQuery({
      location,
      match,
      history,
      include: Topics.include,
      fields: Topics.fields,
      limit: LIMIT,
    }),
  ] });

  const isGameComparePage = isGameCompare(location.pathname);

  return (
    <BasePage>
      <Rubric
        rawRubric={rawRubric}
        rawTopics={rawTopics}
        titleComponent={RubricHeader}
        limit={LIMIT}
        isLoading={topicsAreLoading || rubricIsLoading}
      >
        {({ topics = [] }) => (
          <Topics
            topics={topics}
            isLoading={topicsAreLoading}
            isGameComparePage={isGameComparePage}
          />
        )}
      </Rubric>
    </BasePage>
  );
}

RubricPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};
