import PropTypes from 'prop-types';

import { denormalizeData } from 'core/utils/api';
import resolveRelationships from 'core/utils/relationships';
import { renderError } from 'core/utils/app-status-helper';

import useRequest from 'core/hooks/useRequest';

import { bebop as bebopApi } from 'core/api/definitions/bebop';

import Page from 'core/components/Page';
import PageLoader from 'core/components/Loader/PageLoader';
import H1 from 'core/components/H1';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withTheme from 'core/components/theme';

import { VERTICAL, VerticalIndent } from 'site/components/Indents';
import { IndentWrap } from 'site/components/Wrappers';
import BasePage from 'site/components/BasePage';
import SocialShare from 'site/components/SocialShare';
import CompatibilityWidget from 'site/components/CompatibilityWidget';

import NotFound from 'site/pages/not-found';

import {
  SOVMESTIMOST_LINK,
  SOVMESTIMOST_MALE_NAME_TITLE,
  SOVMESTIMOST_MALE_NAME_DESCRIPTION,
  SOVMESTIMOST_FEMALE_NAME_TITLE,
  SOVMESTIMOST_FEMALE_NAME_DESCRIPTION,
} from 'site/constants';

import CompatibilityPair from '../CompatibilityPair';
import styles from './index.styl';


const relationships = resolveRelationships(['name_compatibility'], {});

const OneName = (props) => {
  const {
    isMobile,
    theme,
    match: {
      params: {
        sex,
        name,
      },
    },
    history,
  } = props;

  const { data: pairs, isLoading } = useRequest({
    queryKey: ['bebopApi', 'getTopics', {
      topic_type: 'name_compatibility',
      include: 'name_compatibility',
      fields: 'link',
      plugin: JSON.stringify({
        name_compatibility: {
          [sex + '_slug']: name,
        },
      }),
    }],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params)
      .then(denormalizeData)
      .then(data => {
        if (!data?.length) {
          return Promise.reject(Object.assign(new Error('Not Found'), { statusCode: 404 }));
        }

        return data;
      })
      .catch(err => renderError(err, history)),
  });

  if (isLoading) return <PageLoader />;
  if (!pairs?.length) return <NotFound />;

  const topSpacing = isMobile ? 15 : VERTICAL;
  const sortedPairs = pairs.sort((pair1, pair2) => {
    const { name_compatibility: nameCompatibility1 } = relationships(pair1);
    const { name_compatibility: nameCompatibility2 } = relationships(pair2);

    return nameCompatibility1.overall > nameCompatibility2.overall ? -1 : 1;
  });

  const oppositeSex = sex === 'male' ? 'female' : 'male';
  const mainName = sortedPairs[0].relationships.name_compatibility.data.attributes[sex + '_name'];
  const bestPairs = sortedPairs.slice(0, 5);
  const otherPairs = sortedPairs.slice(5);
  const goodPairsCount = Math.round((65 / 100) * otherPairs.length);
  const goodPairs = otherPairs.slice(0, goodPairsCount);
  const worstPairs = otherPairs.slice(goodPairsCount);
  const title = sex === 'male' ? SOVMESTIMOST_MALE_NAME_TITLE : SOVMESTIMOST_FEMALE_NAME_TITLE;
  const description = sex === 'male' ? SOVMESTIMOST_MALE_NAME_DESCRIPTION : SOVMESTIMOST_FEMALE_NAME_DESCRIPTION;
  return (
    <Page
      title={title.replace(/{name}/g, mainName)}
      description={description.replace(/{name}/g, mainName)}
    >
      <BasePage isCompatibilityPage>
        <style jsx>{`
          .${styles.hint}
            font bold 12px/1.4 ${theme.fonts.nuance}
          .title
            font bold italic ${isMobile ? '20px' : '26px'}/1.2 ${theme.fonts.display}
            color ${theme.colors.primary}
        `}</style>
        <IndentWrap
          top={topSpacing}
          right={isMobile ? 15 : 0}
          left={isMobile ? 15 : 0}
        >
          <div className={styles.bestMatches}>
            <div className={styles.mainNameWrapper}>
              <span className={styles.hint}>Проверка совместимости имен</span>
              <H1>{mainName}</H1>
            </div>
            <VerticalIndent indent={40} />
            <div className='title'>Самые совместимые имена</div>
            <VerticalIndent indent={20} />
            <ul className={styles.list}>
              {bestPairs.map((pair, i) => (<CompatibilityPair
                key={i}
                pair={pair}
                sex={oppositeSex}
              />))}
            </ul>
            {isMobile && (
              <div className={styles.socialShareWrapper}>
                <SocialShare url={`${SOVMESTIMOST_LINK}/${sex}/${name}`} />
              </div>
            )}
          </div>
          <VerticalIndent indent={20} />
          {!isMobile && (
            <div className={styles.socialShareWrapper}>
              <SocialShare url={`${SOVMESTIMOST_LINK}/${sex}/${name}`} />
            </div>
          )}
        </IndentWrap>
        {otherPairs.length > 0 && (
          <IndentWrap
            top={topSpacing}
            left={isMobile ? 20 : 40}
            right={isMobile ? 20 : 60}
          >
            <div className='title'>Подходящие пары</div>
            <VerticalIndent indent={20} />
            <ul className={styles.list}>
              {goodPairs.map((pair, i) => (<CompatibilityPair
                key={i}
                pair={pair}
                sex={oppositeSex}
              />))}
            </ul>
            <VerticalIndent indent={40} />
            <div className='title'>Наименее совместимые</div>
            <VerticalIndent indent={20} />
            <ul className={styles.list}>
              {worstPairs.map((pair, i) => (<CompatibilityPair
                key={i}
                pair={pair}
                sex={oppositeSex}
              />))}
            </ul>
          </IndentWrap>
        )}
        <VerticalIndent indent={40} />
        <CompatibilityWidget />
      </BasePage>
    </Page>
  );
};

OneName.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(OneName));
