import { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import useRequest from 'core/hooks/useRequest';

import { bebop as bebopApi } from 'core/api/definitions/bebop';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import Feed from 'core/components/Feed';
import Link from 'core/components/Link';
import Page from 'core/components/Page';
import PageLoader from 'core/components/Loader/PageLoader';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import RubricHeader from 'site/components/RubricHeader';
import BasePage from 'site/components/BasePage';

import Card1 from 'site/cards/Card1';

import { getGroups } from 'site/utils';

import styles from './index.styl';


const LIMIT = 100;

function Data(props) {
  const { breakpoint, location: { pathname } } = props;
  const [rubricRoot, rubric] = pathname.split('/').filter(Boolean);

  const commonApiParams = {
    rubric,
    rubric_root: rubricRoot,
    include: 'rubric',
    fields: filterRequiredParams([Card1], 'fields'),
  };

  const [{
    data: firstTopics,
    isLoading: firstTopicsLoading,
  }, {
    data: rubrics,
    isLoading: rubricsLoading,
  }] = useRequest({
    queries: [
      {
        queryKey: ['bebopApi', 'getTopics', {
          ...commonApiParams,
          with_filtered_count: 1,
        }],
        queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
      }, {
        queryKey: ['bebopApi', 'getRubric', {
          rubric_slug: rubric,
          root_slug: rubricRoot,
        }],
        queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
      },
    ],
  });

  const resultsAreLoading = firstTopicsLoading || rubricsLoading;

  const topicsCount = firstTopics?.meta?.filtered_count || 0;
  const iterations = Math.ceil(topicsCount / LIMIT);

  const queries = [];

  for (let i = 1; i < iterations; i++) {
    queries.push({
      queryKey: ['bebopApi', 'getTopics', {
        ...commonApiParams,
        offset: i * LIMIT,
      }],
      queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
    });
  }

  const restTopicsResults = [].concat(useRequest({ queries }) || {});
  const restTopicsAreLoading = restTopicsResults.some(({ isLoading }) => isLoading);

  const allTopics = restTopicsResults
    .filter(({ data }) => !!data)
    .reduce((stack, { data: item }) => {
      return {
        data: stack.data.concat(item.data),
        included: stack.included.concat(item.included),
      };
    }, firstTopics);

  const denormalizedTopics = denormalizeData(allTopics);
  const groups = useMemo(() => getGroups(rubrics, denormalizedTopics), [rubrics, denormalizedTopics]);

  if (resultsAreLoading || restTopicsAreLoading) return <PageLoader />;

  const {
    data: {
      attributes: {
        title,
        meta_title: metaTitle,
        meta_description: metaDescription,
      },
    },
  } = rubrics;

  return (
    <BasePage>
      <Page
        title={metaTitle || title}
        description={metaDescription}
      >
        <RubricHeader rawRubric={rubrics} title={title} />
        <div className={cx(styles.groups, styles[breakpoint])}>
          {groups.map(group => {
            const {
              id,
              attributes: {
                title: letter,
                slug,
                root_slug: rootSlug,
              },
              topics,
            } = group;
            return (
              <div className={styles.group} key={id}>
                <div className={styles.letter}>
                  <Link to={`/${rootSlug}/${slug}`} type='secondary'>
                    {letter}
                  </Link>
                </div>
                <div className={styles.groupList}>
                  <Feed
                    content={topics}
                    card={Card1}
                    interitemSpacing={10}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </Page>
    </BasePage>
  );
}

Data.propTypes = {
  breakpoint: breakpointPropTypes(),
  location: PropTypes.object,
};

export default withBreakpoint(Data);
