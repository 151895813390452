import PropTypes from 'prop-types';

import Page from 'core/components/Page';
import PageLoader from 'core/components/Loader/PageLoader';
import { withBreakpoint } from 'core/components/breakpoint';

import AdFooter from 'site/components/Ads/AdFooter';

import MainPageContent from './MainPageContent';
import useMainContent from './useMainContent';


function MainPage({ isMobile, isDesktop, breakpoint }) {
  const { isLoading, ...content } = useMainContent(isMobile);

  return (
    <Page description='Женский онлайн-журнал Страсти. Свежие новости мира моды, красоты, шопинга, шоу-бизнеса и отношений, эксклюзивные интервью и репортажи'>
      {isLoading ? <PageLoader /> : (
        <MainPageContent
          isMobile={isMobile}
          isDesktop={isDesktop}
          breakpoint={breakpoint}
          {...content}
        />
      )}
      <AdFooter contextCount={3} />
    </Page>
  );
}

MainPage.propTypes = {
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  breakpoint: PropTypes.string,
};

export default withBreakpoint(MainPage);
