import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { topicQuery } from 'core/queries/topic';

import PageLoader from 'core/components/Loader/PageLoader';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import Link from 'core/components/Link';
import Topic from 'core/components/Topic';
import Button from 'core/components/Button';
import Comments from 'core/components/Comments';

import { IndentWrap } from 'site/components/Wrappers';
import AdFooter from 'site/components/Ads/AdFooter';

import TopicWrapper from 'site/pages/topic/TopicWrapper';
import TopicHeader from 'site/pages/topic/TopicHeader';


function CommentsPage(props) {
  const { isDesktop } = props;
  const { data, isLoading } = useRequest(topicQuery(props));

  if (isLoading) return <PageLoader />;

  return (
    <Topic content={data}>
      {content => {
        const {
          attributes: {
            link,
            is_premium: isPremium,
            is_comments_disabled: isCommentsDisabled,
          },
        } = content;

        return (
          <TopicWrapper
            content={content}
            showPartners
          >
            {!isPremium && <TopicHeader content={content} comments />}
            <IndentWrap left={isDesktop ? 0 : 20}>
              <Link
                to={link}
                type='secondary'
              >
                <Button size='medium'>Вернуться к статье</Button>
              </Link>
            </IndentWrap>
            <Comments xid={link} isCommentsDisabled={isCommentsDisabled} />
            <AdFooter />
          </TopicWrapper>
        );
      }}
    </Topic>
  );
}

CommentsPage.propTypes = {
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(CommentsPage);
