import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import { DESKTOP, MOBILE } from 'core/components/breakpoint/values';

import AuthorPage from 'core/components/AuthorPage';
import PageLoader from 'core/components/Loader/PageLoader';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import {
  authorQuery,
  topicsQuery,
} from 'core/queries/authorPage';

import BasePage from 'site/components/BasePage';
import {
  VERTICAL,
  VERTICAL_MOBILE,
  HORIZONTAL,
  HORIZONTAL_MOBILE,
} from 'site/components/Indents';

import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import { IndentWrap } from 'site/components/Wrappers';


const breakpointCards = {
  [DESKTOP]: Card2,
  [MOBILE]: Card3,
};

const LIMIT = 15;

function Author(props) {
  const { breakpoint, isMobile } = props;

  const verticalSpacing = isMobile ? VERTICAL_MOBILE : VERTICAL;
  const sideSpacing = isMobile ? HORIZONTAL_MOBILE : 0;

  const results = useRequest({ queries: [
    authorQuery(props),
    topicsQuery({
      ...props,
      card: breakpointCards[breakpoint],
      limit: LIMIT,
    }),
  ] });
  const [
    { data: author, isLoading: authorIsLoading },
    { data: topics, isLoading: topicsAreLoading },
  ] = results;

  return (
    <BasePage>
      <IndentWrap
        top={verticalSpacing}
        right={sideSpacing}
        left={sideSpacing}
      >
        {authorIsLoading && <PageLoader />}
        {!authorIsLoading && (
          <AuthorPage
            author={author}
            topics={topics}
            limit={LIMIT}
            columns={3}
            grid
            card={breakpointCards[breakpoint]}
            interitemSpacing={isMobile ? HORIZONTAL_MOBILE : HORIZONTAL}
            isLoading={topicsAreLoading}
          />
        )}
      </IndentWrap>
    </BasePage>
  );
}

Author.propTypes = {
  isMobile: PropTypes.bool,
  breakpoint: breakpointPropTypes(),
};

export default withBreakpoint(Author);
