import PropTypes from 'prop-types';
import { Fragment } from 'react';

import useRequest from 'core/hooks/useRequest';

import { topicQuery } from 'core/queries/topic';

import { resolve } from 'core/utils/env';

import PageLoader from 'core/components/Loader/PageLoader';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import InfiniteTopic from 'core/components/InfiniteTopic';
import Topic from 'core/components/Topic';
import { SideIndent } from 'core/components/Wrappers';
import GameCompare from 'core/components/GameCompare';
import { isCompareTopic } from 'core/components/GameCompare/utils';

import { IndentWrap } from 'site/components/Wrappers';
import { VERTICAL } from 'site/components/Indents';

import { NAME_COMPATIBILITY } from 'site/constants';

import TopicWrapper from './TopicWrapper';
import TopicContent from './TopicContent';
import TopicHeader from './TopicHeader';
import TopicFooter from './TopicFooter';
import TopicCompatibility from './TopicCompatibility';


function topicRenderer(topic, enhancedTopic, isDesktop) {
  const {
    content,
    infinityIndex,
  } = enhancedTopic;

  const {
    attributes: {
      is_premium: isPremium,
      topic_type: topicType,
    },
  } = content;

  const Wrapper = isDesktop ? Fragment : SideIndent;
  const isGameCompare = isCompareTopic(topicType);

  const spacing = isDesktop ? VERTICAL : 20;

  const sideSpacings = {
    right: isDesktop ? 0 : spacing,
    left: isDesktop ? 0 : spacing,
  };

  return (
    <TopicWrapper
      content={content}
      showPartners={infinityIndex === 0}
      infinityIndex={infinityIndex}
    >
      {!isPremium &&
        <TopicHeader content={content} isGameCompare={isGameCompare} />
      }
      {isGameCompare ? (
        <IndentWrap {...sideSpacings}>
          <GameCompare content={content} />
        </IndentWrap>
      ) : (
        <Wrapper>
          <TopicContent content={content} />
          <TopicFooter />
        </Wrapper>
      )}
    </TopicWrapper>
  );
}

function SiteTopic(props) {
  const { isDesktop } = props;
  const { data: topic, isLoading } = useRequest(topicQuery(props));

  if (isLoading) return <PageLoader />;

  const { topic_type: topicType } = topic?.attributes || {};

  if (isCompareTopic(topicType)) {
    return (
      <Topic content={topic}>
        {(t, e) => topicRenderer(t, e, isDesktop)}
      </Topic>
    );
  }

  if (topicType === NAME_COMPATIBILITY) {
    return (
      <Topic content={topic}>
        {() => <TopicCompatibility content={topic} />}
      </Topic>
    );
  }

  return (
    <InfiniteTopic
      rcmBlockId={resolve({
        '*': '2fd6af230a6c4beca74acaca187961f6',
        'production': isDesktop ? 'c4dc810d44db44f39d01c7fd843ad4ab' : 'e42ae0b865bc4ccda3f6489329860f80',
      })}
      content={topic}
    >
      {(t, e) => topicRenderer(t, e, isDesktop)}
    </InfiniteTopic>
  );
}

SiteTopic.propTypes = {
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(SiteTopic);
