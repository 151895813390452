import { topicsQuery } from 'core/queries/topics';

import { filterRequiredParams } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';

import useRequest from 'core/hooks/useRequest';

import Card1 from 'site/cards/Card1';
import Card3 from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';
import Card5 from 'site/cards/Card5';
import CardExpert from 'site/cards/CardExpert';

import { MAIN_BLOCK_LIMIT, TODAY_PERIOD } from 'site/constants';


export default function useMainContent(isMobile) {
  const {
    data: mainTopics,
    isLoading: mainTopicsAreLoading,
    isFetched: mainTopicsAreFetched,
  } = useRequest(topicsQuery({
    limit: MAIN_BLOCK_LIMIT,
    list: 'main',
    sort: 'list',
    include: filterRequiredParams([Card4], 'include'),
    fields: filterRequiredParams([Card4], 'fields'),
    visibility: 'main_page',
    renderError: () => null,
  }));

  const results = useRequest({
    queries: [
      topicsQuery({
        limit: 9,
        include: filterRequiredParams([Card1], 'include'),
        fields: filterRequiredParams([Card1], 'fields'),
        sort: '-views_count',
        days_period: TODAY_PERIOD,
        renderError: () => null,
      }), topicsQuery({
        limit: isMobile ? 5 : 10,
        include: filterRequiredParams([Card3], 'include'),
        fields: filterRequiredParams([Card3], 'fields'),
        rubric: 'nekrolog',
        visibility: 'main_page',
        renderError: () => null,
      }), topicsQuery({
        limit: isMobile ? 5 : 3,
        include: filterRequiredParams([Card4], 'include'),
        fields: filterRequiredParams([Card4], 'fields'),
        rubric: 'lyudi-takie',
        visibility: 'main_page',
        renderError: () => null,
      }), topicsQuery({
        limit: isMobile ? 5 : 6,
        include: filterRequiredParams([Card3], 'include'),
        fields: filterRequiredParams([Card3], 'fields'),
        rubric: 'vyyasnili',
        visibility: 'main_page',
        renderError: () => null,
      }), topicsQuery({
        limit: isMobile ? 5 : 3,
        include: filterRequiredParams([CardExpert], 'include'),
        fields: filterRequiredParams([CardExpert], 'fields'),
        rubric: 'eksklyuzivy',
        visibility: 'main_page',
        renderError: () => null,
      }), topicsQuery({
        limit: isMobile ? 5 : 2,
        include: filterRequiredParams([Card4], 'include'),
        fields: filterRequiredParams([Card4], 'fields'),
        rubric: 'tolko-zvezdy',
        visibility: 'main_page',
        renderError: () => null,
      }),
    ],
  });

  const {
    data: [
      popular,
      nekrolog,
      lyudiTakie,
      vyyasnili,
      eksklyuzivy,
      tolkoZvezdy,
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  const mainTopicsLength = mainTopics?.length || 0;

  const newsLimit = 6;
  const limit = newsLimit + MAIN_BLOCK_LIMIT - mainTopicsLength;
  const excludedIds = mainTopics?.map(item => item.id).join(',');

  const {
    data: news,
    isLoading: newsAreLoading,
  } = useRequest(topicsQuery({
    include: filterRequiredParams([Card3, Card4, Card5], 'include'),
    fields: filterRequiredParams([Card3, Card4, Card5], 'fields'),
    excluded_ids: excludedIds,
    topic_type: 'news',
    sort: '-published_at',
    visibility: 'main_page',
    limit,
    renderError: () => null,
  }, {
    enabled: mainTopicsAreFetched,
  }));

  if (mainTopicsAreLoading || resultsAreLoading || newsAreLoading) return { isLoading: true };

  return {
    mainTopics,
    news,
    popular,
    nekrolog,
    lyudiTakie,
    vyyasnili,
    eksklyuzivy,
    tolkoZvezdy,
  };
}
