import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'core/libs/helmet';

import useRequest from 'core/hooks/useRequest';

import {
  tagQuery,
  topicsQuery,
} from 'core/queries/tagTopics';

import TagTopics from 'core/components/TagTopics';
import PageLoader from 'core/components/Loader/PageLoader';

import BasePage from 'site/components/BasePage';
import Headline from 'site/components/Headline';

import { capitalize } from 'site/utils';

import Topics from '../rubric/Topics';


const TAG_LIMIT = 20;

export default function TagPage({ location, history, match }) {
  const [
    { data: tag, isLoading: tagIsLoading },
    { data: rawTopics, isLoading: topicsAreLoading },
  ] = useRequest({ queries: [
    tagQuery({ history, match }),
    topicsQuery({
      location, history, match,
      include: Topics.include,
      fields: Topics.fields,
      limit: TAG_LIMIT,
    }),
  ] });

  return (
    <BasePage>
      <TagTopics
        tag={tag}
        topics={rawTopics}
        titleComponent={Headline}
        limit={TAG_LIMIT}
        isLoading={topicsAreLoading || tagIsLoading}
      >
        {({ topics, title, descriptionMeta }) => {
          if (tagIsLoading) return <PageLoader />;
          return (
            <Fragment>
              <Helmet>
                <meta name='description' content={descriptionMeta || `${capitalize(title)} – последние статьи, новости, обзоры и другая свежая информация. Все материалы по теме «${title}» на Passion.ru.`} />
              </Helmet>
              <Topics topics={topics} isLoading={topicsAreLoading} />
            </Fragment>
          );
        }}
      </TagTopics>
    </BasePage>
  );
}

TagPage.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
};
