import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import SearchPage from 'core/components/SearchPage';

import { rawContentQuery } from 'core/queries/searchPage';

import {
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpecSearchNative,
} from 'site/components/Ads/mobile';

import CardSearch from 'site/cards/CardSearch';

import filters from './filters';

import ColumnLayout from 'core/components/ColumnLayout';

import InnerSideColumn from 'site/components/InnerSideColumn';


export default function Search({ location }) {
  const { data, isLoading } = useRequest(rawContentQuery({ location, card: CardSearch }));

  return (
    <ColumnLayout
      rightColumn={(
        <InnerSideColumn
          showBoesque={false}
          showSmi24Vertical={false}
        />
      )}
    >
      <style jsx>{`
        .wrapper
          :global(.mobile) &
            padding 0 20px
      `}</style>
      <div className='wrapper'>
        <SearchPage
          rawContent={data}
          filters={filters}
          ad1={SuperFooter}
          ad2={Context}
          ad1Mobile={Listing1}
          ad2Mobile={ListingSpecSearchNative}
          card={CardSearch}
          isLoading={isLoading}
        />
      </div>
    </ColumnLayout>
  );
}


Search.propTypes = {
  location: PropTypes.object,
};
