import deepmerge from 'core/libs/deepmerge';
import color from 'core/libs/color';

import bindProps from 'core/components/bindProps';

import Logo from 'site/components/Logo';
import PageWrapper from 'site/components/Wrappers/PageWrapper';

import { Card1Type1 } from 'site/cards/Card1';

import fonts from './fonts';

import {
  VERTICAL_MOBILE,
  HORIZONTAL_MOBILE,
} from 'site/components/Indents';


export default function baseMobileTheme(atoms) {
  const texts = {
    headline: {
      font: `bold 26px/1.08 ${fonts.display}`,
    },

    title1: {
      font: `bold 26px/1.08 ${fonts.display}`,
    },

    title2: {
      font: `italic 26px/1.25 ${fonts.display}`,
    },

    title3: {
      font: `bold italic 26px/1.8 ${fonts.display}`,
    },

    title4: {
      color: atoms.colors.hint,
      font: `bold 16px/24px ${fonts.display}`,
    },

    title5: {
      font: `bold 16px/24px ${fonts.display}`,
    },

    title6: {
      font: `italic bold 26px/1.3 ${fonts.display}`,
    },

    body: {
      font: `18px/26px ${fonts.text}`,
    },

    lead: {
      font: `400 18px/26px ${fonts.nuance}`,
      marginTop: '15px',
    },

    quote: {
      font: `italic 22px/28px ${fonts.text}`,
    },

    caption1: {
      color: atoms.colors.hint,
      font: `12px/14px ${fonts.nuance}`,
    },

    caption2: {
      font: `13px/14px ${fonts.text}`,
    },

    caption3: {
      font: `13px/14px ${fonts.text}`,
    },

    caption4: {
      font: `400 12px/15px ${fonts.display}`,
      color: atoms.colors.grey,
    },
  };

  const vikontReadMore = {
    block: {
      padding: '15px',
      borderRadius: 0,
    },
    drum: {
      itemWidth: 282,
      spaceBetween: 20,
    },
    title: {
      marginBottom: '15px',
    },
    singleCard: {
      alignItems: 'center',
    },
  };

  const vikontIncut = {
    borderWidth: '0',
    padding: '0',
    font: texts.quote.font,
  };

  const colors = {
    layout: '#fff',
    content: '#fff',
  };

  const scooter = {
    logo: {
      width: 150,
      height: 50,
    },
    social: {
      size: 34,
      iconSize: 16,
      borderRadius: 18,
      margin: 0,
    },
    background: colors.content,
  };

  const shapka = {
    logo: {
      Icon: bindProps({ type: 'mobile' })(Logo),
      width: 118,
      height: 56,
    },
    dropdown: {
      background: colors.layout,
    },
    background: colors.content,
    stickyBackground: colors.content,
    backgroundOpened: colors.content,
  };

  const expert = {
    name: {
      idle: {
        font: `400 16px/20px ${fonts.nuance}`,
      },
      hover: {
        font: `400 16px/20px ${fonts.nuance}`,
      },
    },
    jobTitle: {
      font: `400 14px/18px ${fonts.nuance}`,
    },
    label: {
      font: `700 italic 12px/13px ${fonts.display}`,
    },
    avatar: {
      width: '60',
    },
  };

  const gallery = {
    slide: {
      background: color(atoms.colors.primary).alpha(0.1).string(),
    },
  };

  const topicContent = {
    tableOfContents: {
      padding: '0 20px 15px 20px',
      margin: '0 0 30px 0',
      borderBottom: `1px solid ${atoms.colors.divider}`,
      maxWidth: 'none',
      title: texts.title2.font,
    },
  };


  const topicFooter = {
    commentButton: {
      topSpacing: VERTICAL_MOBILE,
      size: 'small',
    },
    block: {
      interitemSpacing: 10,
      spacing: VERTICAL_MOBILE,
    },
  };

  const ads = {};

  const errorPage = {
    padding: '10px 0',
    card: Card1Type1,
    title: {
      font: `bold 58px/70px ${fonts.display}`,
    },
    subtitle: {
      font: `24px/28px ${fonts.text}`,
    },
    popular: {
      padding: '0 15px',
    },
  };

  const layout = {
    indents: {
      top: VERTICAL_MOBILE,
      right: HORIZONTAL_MOBILE,
      bottom: VERTICAL_MOBILE,
      left: HORIZONTAL_MOBILE,
    },
  };

  const captionCredits = {
    caption: {
      font: `400 12px/15px ${fonts.display}`,
      color: atoms.colors.primary,
    },
    credits: {
      font: `400 12px/15px ${fonts.display}`,
      color: atoms.colors.grey,
    },
  };

  const topicHeaderTexts = {
    title: {
      font: `700 28px/31px ${fonts.display}`,
    },
    subtitle: {
      font: `400 26px/31px ${fonts.display}`,
    },
    firstLetter: {
      font: `900 44px/31px ${fonts.display2}`,
    },
    divider: {
      margin: '15px calc(100% - 300px) 15px 0',
      background: color(atoms.colors.primary).alpha(0.15).string(),
    },
  };

  const smartTeaser = {
    carRelease: {
      title: {
        font: `700 16px/22px ${fonts.display}`,
      },
      info: {
        font: `400 14px/19px ${fonts.display}`,
      },
    },
  };

  const gameCompare = {
    indent: PageWrapper,
    compare: {
      margin: '0 auto',
      progressType: 3,
    },
    leaderBoard: {
      caption: `300 15px/22px ${fonts.text}`,
      modalWindow: {
        color: atoms.colors.primary,
      },
    },
    concidences: {
      font: `400 15px/19px ${fonts.text}`,
      margin: '0 0 15px',
    },
    winner: {
      borderRadius: 0,
      viewBox: '0 0 870 1200',
      padding: '20px 15px',
    },
    tooltip: {
      scheme: {
        titleFont: `700 18px/29px ${fonts.display}`,
        textFont: `400 16px/23px ${fonts.display}`,
      },
    },
    share: {
      marginTop: 0,
      showBorder: true,
    },
  };

  return deepmerge({
    colors,
    controls: {
      vikontReadMore,
      vikontIncut,
      scooter,
      shapka,
      expert,
      gallery,
      topicContent,
      topicFooter,
      captionCredits,
      topicHeaderTexts,
      smartTeaser,
      gameCompare,
    },
    ads,
    pages: {
      notFound: errorPage,
    },
    layout,
    texts,
  }, atoms);
}
